@charset "utf-8";

@import "so-simple";

/* Editorial */
.editorial-header a, .editorial-header a:hover, .editorial-header a:visited .editorial-header a:active {
        text-decoration: none;
}

.editorial-header h2 {
    font-family: "Lora", serif;
    font-size: calc(30px + 6 * ((100vw - 320px) / 960));
    font-weight: bold;
    font-style: italic;
    letter-spacing: -1px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom:  1rem;
}

.editorial-author {
    text-align: center;
    margin-bottom: 0.5rem;
}


/*
.author-urls-wrapper {
  text-align: center;
}

.author-urls-wrapper  a {
  padding-left: 5px;
  padding-right: 5px;
}


.container {
    padding: 2px 16px;
}
*/

/* Pie de página */

.footerinfo li {
  display: inline;
}

.footerinfo li + li::before {
  content: " | ";
  padding: 0 10px;
}
